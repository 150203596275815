
import { Component, Vue,Inject } from "vue-property-decorator";
import { houseLog } from "@/api";
import LogTable from "@/components/LogTable.vue";

@Component({ components: { LogTable } })
export default class Log extends Vue {
    @Inject() passwordInit!: () => void;
    private id = "";
    private list = [];

    created() {
        this.passwordInit();
        this.id = this.$route.params.id;
        this.houseLog();
    }

    async houseLog() {
        const { data } = await houseLog(this.id);
        this.list = data;
    }
}
